export default function initIntroTimer() {
    const container = document.querySelector('.js-init-intro-timer');
    if(container) {
        const daysValue = container.querySelector('.js-timer-days-value');
        const daysLabel = container.querySelector('.js-timer-days-label');

        const hoursContainer = container.querySelector('.intro__timer-item--h');
        const hoursValue = hoursContainer.querySelector('.intro__timer-value');
        const hoursLabel = hoursContainer.querySelector('.intro__timer-label');

        const minutesContainer = container.querySelector('.intro__timer-item--m');
        const minutesValue = minutesContainer.querySelector('.intro__timer-value');
        const minutesLabel = minutesContainer.querySelector('.intro__timer-label');

        const secondsContainer = container.querySelector('.intro__timer-item--s');
        const secondsValue = secondsContainer.querySelector('.intro__timer-value');
        const secondsLabel = secondsContainer.querySelector('.intro__timer-label');

        let timestamp = container.dataset.start;

        const isEn = container.dataset.lang === 'en';

        const timer = setInterval(function () {
            const now = new Date();

            const diff = timestamp * 1000 - now.getTime();

            // Проверяем, что дата еще не наступила
            if (diff <= 0) {
                secondsValue.textContent = "0";
                if (isEn) {
                    secondsLabel.textContent = "secs";
                } else {
                    secondsLabel.textContent = "секунд";
                }
                minutesValue.textContent = "0";
                if (isEn) {
                    minutesLabel.textContent = "mins";
                } else {
                    minutesLabel.textContent = "минут";
                }
                hoursValue.textContent = "0";
                if (isEn) {
                    hoursLabel.textContent = "hours";
                } else {
                    hoursLabel.textContent = "часов";
                }
                daysValue.textContent = "0";
                if (isEn) {
                    daysLabel.textContent = "days";
                } else {
                    daysLabel.textContent = "дней";
                }
                clearInterval(timer);
                container.classList.remove('animatable')
                return;
            }

            // Получаем секунды
            const seconds = div(diff, 1000) % 60;
            secondsValue.textContent = seconds;
            if (isEn) {
                secondsLabel.textContent = formatLabel(seconds, ["secs", "secs", "secs"]);
            } else {
                secondsLabel.textContent = formatLabel(seconds, ["секунда", "секунды", "секунд"]);
            }

            // Получаем минуты
            const minutes = div(diff, 60000) % 60;
            minutesValue.textContent = minutes;
            if (isEn) {
                minutesLabel.textContent = formatLabel(minutes, ["mins", "mins", "mins"]);
            } else {
                minutesLabel.textContent = formatLabel(minutes, ["минута", "минуты", "минут"]);
            }

            // Получаем часы
            const hours = div(diff, 3600000) % 24;
            hoursValue.textContent = hours;
            if (isEn) {
                hoursLabel.textContent = formatLabel(hours, ["hours", "hours", "hours"]);
            } else {
                hoursLabel.textContent = formatLabel(hours, ["час", "часа", "часов"]);
            }

            // Получаем дни
            const days = div(div(diff, 3600000), 24);
            daysValue.textContent = days;
            if (isEn) {
                daysLabel.textContent = formatLabel(days, ["days", "days", "days"]);
            } else {
                daysLabel.textContent = formatLabel(days, ["день", "дня", "дней"]);
            }

        }, 1000);

        container.classList.add('animatable')
    }

    function div(a, b) {
        return (a - a % b) / b;
    }

    function formatLabel(num, variants) {
        if (num % 100 === 11 || num % 100 === 12 || num % 100 === 13 || num % 100 === 14 || num % 10 === 0 || num % 10 === 5 || num % 10 === 6 || num % 10 === 7 || num % 10 === 8 || num % 10 === 9) {
            return variants[2];
        }
        if (num % 10 === 1) {
            return variants[0];
        }
        if (num % 10 === 2 || num % 10 === 3 || num % 10 === 4) {
            return variants[1];
        }
    }
}