import panzoom from "panzoom";

export default function plan() {
    const containers = Array.from(document.querySelectorAll('.js-init-plan'));

    containers.forEach(container => {
        const tabs = Array.from(container.querySelectorAll('.js-tab'));
        const bodies = Array.from(container.querySelectorAll('.js-body'));

        // hover
        bodies.forEach(body => {
            const members = Array.from(body.querySelectorAll('.js-member-btn'));
            const planItems = Array.from(body.querySelectorAll('.js-member-plan-item'));

            const membersAccordion = body.querySelector('.plan__members-block');

            let currentZoom = 1;
            const scene = body.querySelector('.js-drag-element');

            let pzInstance;

            if (scene && !scene.classList.contains('dont')) {
                pzInstance = panzoom(scene, {
                    maxZoom: 2,
                    minZoom: 0.5,
                    initialX: 0,
                    initialY: 0,
                    initialZoom: currentZoom,
                    beforeWheel: function (e) {
                        return false;
                    },
                    onTouch: function (e) {
                        return false;
                    }
                });
            }

            if (members && planItems && membersAccordion) {
                const membersAccordionContent = membersAccordion.querySelector('.plan__members-list-wrapper');

                let dependensies = {};

                members.forEach(member => {
                    planItems.forEach(planItem => {
                        if (member.dataset.id == planItem.dataset.id) {
                            dependensies[member.dataset.id] = {
                                member,
                                planItem
                            }
                        }
                    })
                })

                const toggler = body.querySelector('.plan__radio-block');
                const scheme3dBlock = body.querySelector('.plan__scheme-3d-svg-block');
                const labels = body.querySelectorAll('.plan__radio-label');
                toggler.addEventListener('click', () => {
                    scheme3dBlock.classList.toggle('active');
                    toggler.classList.toggle('active');
                    labels.forEach(label => {
                        label.classList.toggle('active');
                    })
                });

                if (pzInstance) {
                    const zoomerContainer = body.querySelector('.plan__zoomer');
                    const zoomIn = zoomerContainer.querySelector('.plan__zoomer-btn.in');
                    const zoomOut = zoomerContainer.querySelector('.plan__zoomer-btn.out');


                    zoomIn.addEventListener('click', () => {
                        currentZoom += 0.4;
                        pzInstance.smoothZoom(scene.clientWidth / 2, scene.clientHeight / 2, currentZoom);
                    })

                    zoomOut.addEventListener('click', () => {
                        currentZoom -= 0.4;
                        pzInstance.smoothZoom(scene.clientWidth / 2, scene.clientHeight / 2, currentZoom);
                    })
                }

                members.forEach(member => {
                    member.addEventListener('mouseover', () => {
                        for (let key in dependensies) {
                            if (member.dataset.id == key) {
                                dependensies[key].member.classList.add('hovered');
                                dependensies[key].planItem.classList.add('hovered');
                            }
                        }
                    })

                    member.addEventListener('mouseleave', () => {
                        for (let key in dependensies) {
                            if (member.dataset.id == key) {
                                dependensies[key].member.classList.remove('hovered');
                                dependensies[key].planItem.classList.remove('hovered');
                            }
                        }
                    })

                    if (pzInstance) {
                        member.addEventListener('click', () => {
                            const memberX = member.dataset.x;
                            const memberY = member.dataset.y;

                            membersAccordion.classList.remove('active');
                            membersAccordionContent.style.height = 0;

                            if (window.matchMedia("(max-width: 768px)").matches) {
                                pzInstance.smoothMoveTo(-memberX + 100, -memberY + 100);
                            } else {
                                pzInstance.smoothMoveTo(-memberX + 500, -memberY + 500);
                            }
                        })
                    }
                });
            }
        })
    })
}

function setActive(elements, index) {
    elements.forEach((element, i) => {
        if (i === index) {
            element.classList.add('active');
        } else {
            element.classList.remove('active');
        }
    })
}