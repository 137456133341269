export default function formSubmitHandler() {
    const pristineConfig = {
        // class of the parent element where the error/success class is added
        classTo: 'js-validator-wrapper',
        errorClass: 'invalid',
        successClass: 'valid',
        // class of the parent element where error text element is appended
        errorTextParent: 'js-validator-wrapper',
        // type of element to create for the error text
        errorTextTag: 'span',
        // class of the error text element
        errorTextClass: 'form__input-error'
    };

    const forms = Array.from(document.querySelectorAll('.js-handle-feedback-form'));
    forms.forEach(form => {
        const url = form.action;
        const submitBtn = form.querySelector('.js-disable-on-send');

        const validator = new Pristine(form, pristineConfig, true);
        const allValidatedInputs = Array.from(form.querySelectorAll('.js-validator-wrapper'));

        form.addEventListener('submit', (e) => {
            e.preventDefault();

            const isValid = validator.validate();
            if(!isValid) return;

            const parentContainer = form.closest('.form-modal__window');
            let successBlock = {};
            let errorBlock = {};
            let formBlock = {};
            let successVisitorText = "";
            if (parentContainer) {
                successVisitorText = parentContainer.querySelector('.js-visitor-success-text');
                successBlock = parentContainer.querySelector('.form-modal__message--success');
                errorBlock = parentContainer.querySelector('.form-modal__message--error');
                formBlock = parentContainer.querySelector('.form-modal__container');
            }

            // Yandex Metrika
            const formGoal = form.dataset.goal;
            if (formGoal && window.ym) {
                ym(88241357, 'reachGoal', formGoal);
            }

            submitBtn.classList.add('disabled');

            const formData = new FormData(form);

            fetch(url, {
                body: formData,
                method: "POST"
            })
                .then(response => {
                    if (response.ok) {
                        // <<<< RESET ALL KOSTIL
                        form.reset();

                        allValidatedInputs.forEach(inputWrapper => {
                            const input = inputWrapper.querySelector('.js-validate-multiple')
                                ? inputWrapper.querySelector('.js-validate-multiple').querySelector('input')
                                : null;
                        })

                        // костыли для choices.js
                        window.choicesItems.forEach(choices => {
                            choices.destroy();
                            choices.init();

                            // для multiple все еще хуже
                            const select = choices.passedElement.element;
                            const input = select.closest('.js-validate-multiple') ? select.closest('.js-validate-multiple').querySelector('input') : null;
                            if (select.hasAttribute('multiple')) {
                                choices.passedElement.element.addEventListener('choice', function (event) {
                                    choices.getValue(true).forEach(item => {
                                        if (item !== event.detail.choice.value) return;
                                        setTimeout(() => {
                                            choices.removeActiveItemsByValue(event.detail.choice.value);
                                            setValue(choices, input);
                                        }, 100)
                                    });
                                });

                                choices.passedElement.element.addEventListener('change', () => {
                                    setValue(choices, input);
                                })

                                setValue(choices, input);

                                const multipleLabel = select.dataset.name || "";
                                select.parentElement.textContent = multipleLabel;
                            }

                            function setValue(choices, input) {
                                if (input) {
                                    input.value = choices.getValue(true).length ? JSON.stringify(choices.getValue(true)) : "";
                                    const evt = document.createEvent('HTMLEvents');
                                    evt.initEvent('input', true, true);
                                    input.dispatchEvent(evt);
                                }
                            }
                        })

                        // Сбрасываем косяки валидации
                        allValidatedInputs.forEach(inpt => {
                            inpt.classList.remove('focused');
                            inpt.classList.remove('filled');
                            inpt.classList.remove('valid');
                            inpt.classList.remove('invalid');
                        });

                        // Если в форме есть табы
                        // Делаем активным первый таб
                        const formTabs = Array.from(form.querySelectorAll('.js-form-tab'));
                        const formTabsContainer = form.closest('.js-form-tabs');
                        if (formTabsContainer) {
                            const stepsLabel = formTabsContainer.querySelector('.js-tabs-steps');
                            const isEn = stepsLabel.dataset.lang == "en";
                            const stepsLabelPart = isEn ? "Step" : "Шаг";

                            if (formTabs && stepsLabel) {
                                setActive(formTabs, 0);
                                stepsLabel.textContent = `${stepsLabelPart} 1/${formTabs.length}`;
                            }
                        }

                        window.citySelect.disable();
                        window.regionSelect.disable();

                        function setActive(elements, index) {
                            elements.forEach((elem, i) => {
                                if(i === index) {
                                    elem.classList.add('active');
                                } else {
                                    elem.classList.remove('active');
                                }
                            })
                        }

                        // END RESET ALL KOSTIL >>>>

                        if (successBlock && formBlock) {
                            if (successVisitorText) {
                                successVisitorText.textContent = successVisitorText.textContent.replace('#EMAIL#', formData.get("email"));
                            }
                            showSuccess(successBlock, formBlock);
                        }
                    } else {
                        if (errorBlock && formBlock) {
                            showError(errorBlock, formBlock);
                        }
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
                .finally(() => {
                    submitBtn.classList.remove('disabled');
                })
        })
    });
}

function showSuccess(successElement, formElement) {
    successElement.classList.add('active');
    formElement.classList.add('hidden');

    setTimeout(() => {
        successElement.classList.remove('active');
        formElement.classList.remove('hidden');
        window.modalApi.close();
    }, 5000)
}

function showError(errorElement, formElement) {
    errorElement.classList.add('active');
    formElement.classList.add('hidden');

    setTimeout(() => {
        errorElement.classList.remove('active');
        formElement.classList.remove('hidden');
        window.modalApi.close();
    }, 5000)
}
