export default function membersFilter() {
    const containers = Array.from(document.querySelectorAll('.js-members-filter-container'));
    containers.forEach((container, i) => {
        const form = container.querySelector('.js-auto-submit-form');
        const content = container.querySelector('.js-members-filter-content');

        const inputs = Array.from(form.querySelectorAll('input[type="text"]'));
        inputs.forEach(input => {

            let timer;
            input.addEventListener('keyup', () => {
                clearTimeout(timer);
                if (input.value.length < 3) {
                    content.classList.add('hidden');
                } else {
                    content.classList.remove('hidden');
                }
                timer = setTimeout(() => {
                    if (input.value.length < 3) {
                        return;
                    }

                    // Добавляем класс контейнеру во время загрузки данных
                    content.classList.add('loading');

                    const formData = new FormData(form);
                    const data = [...formData.entries()];
                    const query = data
                        .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
                        .join('&');
                    const url = `?${query}`;

                    fetch(url, {
                        method: 'GET',
                        mode: 'no-cors',
                        headers: {
                            'Content-Type': 'text/html'
                        }
                    })
                        .then(res => res.text())
                        .then(data => {
                            const parser = new DOMParser();
                            const page = parser.parseFromString(data, "text/html");

                            // Ищем все ajax контейнеры,
                            // из них понадобится только один с текущим индексом i
                            const newAjaxContainers = page.querySelectorAll('.js-members-filter-container');

                            // Находим элементы, вставляем в текущую разметку
                            const newContent = newAjaxContainers[i].querySelector('.js-members-filter-content');

                            content.innerHTML = newContent.innerHTML;
                        })
                        .catch(function (err) {
                            console.warn('Ошибка при загрузке новых карточек.', err);
                        })
                        .finally(() => {
                            // Удаляем класс для загрузки
                            content.classList.remove('loading');
                        })
                }, 700)
            })
        })
    })
}