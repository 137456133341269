import {Swiper, EffectFade} from "swiper";

Swiper.use([EffectFade]);

export default function initProgramTabs() {
    const container = document.querySelector('.js-init-program-tabs');
    if (container) {
        const navContainer = container.querySelector('.js-nav-container');
        const navButtons = Array.from(navContainer.querySelectorAll('.program__tab'));

        const tabsSliderElement = container.querySelector('.js-tabs-slider');
        const tabsSlides = Array.from(tabsSliderElement.querySelectorAll('.program__tab-body-wrapper'));

        navButtons.forEach((button, i) => {
            button.addEventListener('click', () => {
                navButtons.forEach((b, j) => {
                    const bWrapper = b.closest('.program__tab-wrapper');
                    if (j === i) {
                        bWrapper.classList.add('active');
                        tabsSlides[j].classList.add('active');
                    } else {
                        bWrapper.classList.remove('active');
                        tabsSlides[j].classList.remove('active');
                    }
                })
            })
        })

        // Настраиваем показ картинок по клику на аккордионы
        if (window.matchMedia("(min-width: 1023px)").matches) {
            tabsSlides.forEach(tabElement => {
                const imagesSliderElement = tabElement.querySelector('.js-images-slider');
                const imagesSlider = new Swiper(imagesSliderElement, {
                    effect: 'fade',
                    fadeEffect: {
                        crossFade: true
                    },
                    allowTouchMove: false,
                    autoHeight: true
                });

                const accordionButtons = Array.from(tabElement.querySelectorAll('.program__item-btn'));
                accordionButtons.forEach((button, i) => {

                    button.addEventListener('click', () => {
                        imagesSlider.slideTo(i);
                    })
                })
            })
        }
    }
}