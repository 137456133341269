// import polyfills from './build-in/polyfills';
import './build-in/lazyload';
import detectTouch from './build-in/detectTouch';
import setScrollbarWidth from './build-in/setScrollbarWidth';
import anchorLinks from './build-in/anchorLinks';
import masks from './build-in/masks';
import validation from "./build-in/validation";

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import editableTextContainer from './custom/editableTextContainer';
import set100vh from './custom/set100vh';
import setFirstBlockPadding from './custom/setFirstBlockPadding';
import initSliders from './custom/initSliders';
import fix from './custom/fix';
import initModals from './custom/initModals';
import initAnimations from './custom/initAnimations';
import initIntroTimer from "./custom/initIntroTimer";
import initBackgroundImages from "./custom/initBackgroundImages";
import benefitsTabs from "./custom/benefitsTabs";
import initYandexMap from "./custom/initYandexMap";
import toggleHeader from "./custom/toggleHeader";
import initZillaSelect from "./custom/initZillaSelect";
import initSelects from "./custom/initSelects";
import alignHeights from "./custom/alignHeights";
import formatsMobileOnScroll from "./custom/formatsMobileOnScroll";
import initYouTubePlayers from "./custom/initYouTubePlayers";
import accordions from "./build-in/accordions";
import initProgramTabs from "./custom/programTabs";
import initValidation from "./custom/initValidation";
import initMembersNav from "./custom/initMembersNav";
import formTabs from "./custom/formTabs";
import openModalByHash from "./custom/openModalByHash";
import routesSlider from "./custom/routesSlider";
import { Fancybox } from "@fancyapps/ui";
import initFancybox from "./custom/fancybox";
import tabs from "./custom/tabs";
import imagesSlider from "./custom/imagesSlider";

gsap.registerPlugin(ScrollTrigger);

import showMore from "./backend/showMore";
import formSubmitHandler from "./backend/formSubmitHandler";
import autoFilter from "./backend/autoFilter";
import initAutoSubmitForm from "./backend/initAutoSubmitForm";
import initProgramAutoSubmitForm from "./backend/initProgramAutoSubmitForm";
import membersFilter from "./backend/membersFilter";
import generateLayout from "./custom/generate-layout";
import initFixedFilter from "./custom/initFixedFilter";
import setFixedFilterHeight from "./custom/setFixedFilterHeight";
import plan from "./custom/plan";

document.addEventListener('DOMContentLoaded', function () {
    document.body.classList.add('DOMContentLoaded');

    detectTouch();
    setScrollbarWidth();
    anchorLinks();
    accordions();
    masks();
    validation();

    // custom

    initYouTubePlayers();
    editableTextContainer();
    set100vh();
    setFirstBlockPadding();
    initSliders();
    initAnimations();
    initIntroTimer();
    initBackgroundImages();
    benefitsTabs();
    fix();
    initYandexMap();
    initZillaSelect();
    initSelects();
    formatsMobileOnScroll();
    initProgramTabs();
    initValidation();
    initMembersNav();
    formTabs();
    tabs();

    initModals();
    openModalByHash();
    routesSlider();
    imagesSlider();
    initFancybox();
    generateLayout();
    initFixedFilter();
    plan();

    // Backend
    showMore();
    formSubmitHandler();
    autoFilter();
    initAutoSubmitForm();
    initProgramAutoSubmitForm();
    membersFilter();
});

document.addEventListener('lazyloaded', () => {
    ScrollTrigger.refresh();
});

window.addEventListener('load', function () {
    document.body.classList.add('loaded');
    ScrollTrigger.refresh();
    setTimeout(() => document.body.classList.add('animatable'), 300);

    toggleHeader();
    setFixedFilterHeight();
});