export default function showMore() {
    const ajaxContainers = Array.from(document.querySelectorAll('.js-ajax-show-more-container'));
    ajaxContainers.forEach((container, i) => {
        const elementsContainer = container.querySelector('.js-ajax-elements-container');
        const showMoreBtnContainer = container.querySelector('.js-ajax-show-more-btn-container');
        const showMoreBtn = container.querySelector('.js-ajax-show-more-btn');

        if (!elementsContainer || !showMoreBtnContainer || !showMoreBtn) return;

        showMoreBtn.addEventListener('click', (e) => {
            e.preventDefault();

            // Блокируем кнопку
            showMoreBtn.setAttribute('disabled', 'true');
            showMoreBtn.classList.add('disabled');

            // Добавляем класс контейнеру во время загрузки данных
            elementsContainer.classList.add('loading');

            const url = showMoreBtn.href || window.location.href;

            fetch(url, {
                method: 'GET',
                mode: 'no-cors',
                headers: {
                    'Content-Type': 'text/html'
                }
            })
                .then(res => res.text())
                .then(data => {
                    const parser = new DOMParser();
                    const page = parser.parseFromString(data, "text/html");

                    // Ищем все ajax контейнеры,
                    // из них понадобится только один с текущим индексом i
                    const newAjaxContainers = page.querySelectorAll('.js-ajax-show-more-container');

                    // Находим элементы, вставляем в текущую разметку
                    const newElementsContainer = newAjaxContainers[i].querySelector('.js-ajax-elements-container');
                    const newElements = newElementsContainer.querySelectorAll('.js-ajax-element');

                    const isEditMode = !!document.querySelector('.bx-panel-toggle-on');

                    newElements.forEach(element => {

                        const id = element.id;
                        const editLink = element.dataset.editLink;
                        const deleteLink = element.dataset.deleteLink;

                        elementsContainer.append(element);

                        if (window.BX && isEditMode) {
                            (new BX.CMenuOpener({
                                'parent': id,
                                'menu':[
                                    {
                                        'ICONCLASS':'bx-context-toolbar-edit-icon',
                                        'TITLE':'',
                                        'TEXT':'Изменить элемент',
                                        'ONCLICK':'(new BX.CAdminDialog({\'content_url\':\''+ editLink +'\',\'width\':\'780\',\'height\':\'500\'})).Show()'
                                    },
                                    {
                                        'ICONCLASS':'bx-context-toolbar-delete-icon',
                                        'TITLE':'',
                                        'TEXT':'Удалить элемент',
                                        'ONCLICK':'if(confirm(\'Будет удалена вся информация, связанная с этой записью. Продолжить?\')) jsUtils.Redirect([], \''+ deleteLink +'\');'                                    }
                                ]})).Show();
                            BX.admin.setComponentBorder(id);
                        }
                    });

                    // Находим новую кнопку "Показать ещё", обновляем ее href
                    // Либо удаляем
                    const newShowMoreBtn = newAjaxContainers[i].querySelector('.js-ajax-show-more-btn');
                    if (newShowMoreBtn) {
                        showMoreBtn.href = newShowMoreBtn.href;
                        showMoreBtn.classList.remove('hidden');
                    } else {
                        showMoreBtn.classList.add('hidden');
                    }
                })
                .catch(function (err) {
                    console.warn('Ошибка при загрузке новых карточек.', err);
                })
                .finally(() => {
                    // Разблокируем кнопку
                    showMoreBtn.removeAttribute("disabled");
                    showMoreBtn.classList.remove('disabled');

                    // Удаляем класс для загрузки
                    elementsContainer.classList.remove('loading');
                })
        })
    })
}