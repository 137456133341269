import loadApi from "./loadApi";
import getCoords from "./getCoords";

export default function initYandexMap() {
    // Загружаем api карт только если доскролили до нее
    const mapElement = document.querySelector('#map');
    if (!mapElement) return;
    const loadTrigger = getCoords(mapElement).top;
    const windowHeight = document.documentElement.clientHeight;

    const check = () => {
        const scrollWindow = window.scrollY;

        if (scrollWindow > loadTrigger - windowHeight && scrollWindow < loadTrigger + windowHeight) {
            const url = 'https://api-maps.yandex.ru/2.1/?apikey=ea356c65-c8fa-4cd7-a953-858e5b5608d9&lang=ru_RU';

            loadApi('yandex', url,() => { ymaps.ready(init); });

            window.removeEventListener('scroll', check);
        }
    }

    window.addEventListener('scroll', check, false);

    function init(){
        const mapSettings = {
            center: [mapElement.dataset.initialLongitude, mapElement.dataset.initialLatitude],
            zoom: mapElement.dataset.initialZoom,
            controls: []
        }
        const image = mapElement.dataset.image;

        const map = new ymaps.Map(mapElement, mapSettings);
        map.behaviors.disable('scrollZoom');

        _addPlace(map, {
            coords: [
                mapElement.dataset.initialLongitude,
                mapElement.dataset.initialLatitude
            ],
            image
        })
    }

    function _addPlace(map, {coords, image}) {
        const placemarkProperties = {};

        let placemarkOptions = {};

        if(document.documentElement.clientWidth > 1024) {
            placemarkOptions = {
                iconLayout: 'default#image',
                iconImageHref: image,
                iconImageSize: [75, 75],
                iconImageOffset: [-37.5, -37.5]
            };
        } else {
            placemarkOptions = {
                iconLayout: 'default#image',
                iconImageHref: image,
                iconImageSize: [40, 40],
                iconImageOffset: [-20, -20]
            };
        }

        const placemark = new ymaps.Placemark(coords, placemarkProperties, placemarkOptions);

        map.geoObjects.add(placemark);
    }
}