import {Swiper} from "swiper";

export default function imagesSlider() {
    const containers = Array.from(document.querySelectorAll('.js-init-images-slider'));

    containers.forEach(container => {
        // Настраиваем показ картинок по клику на аккордионы
        if (window.matchMedia("(min-width: 1023px)").matches) {

            const imagesSliderElement = container.querySelector('.js-images-slider');
            const imagesSlider = new Swiper(imagesSliderElement, {
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                allowTouchMove: false,
                autoHeight: true
            });

            const accordionButtons = Array.from(container.querySelectorAll('.js-images-slider-btn'));
            accordionButtons.forEach((button, i) => {
                button.addEventListener('click', () => {
                    imagesSlider.slideTo(i);
                })
            })
        }
    })
}