import getCoords from "./getCoords";

export default function toggleHeader() {
    const header = document.querySelector('.js-toggle-header');
    if (header) {
        if (header.classList.contains('header--dark')) return; // Если уже с сервера приходит с классом dark

        const whiteBlocks = Array.from(document.querySelectorAll('.js-white-block'));
        const socials = document.querySelector('.js-toggle-socials');
        const headerHeight = header.clientHeight;

        const footer = document.querySelector('.footer');
        const footerTrigger = getCoords(footer).top;

        if (whiteBlocks && header) {
            let triggers = [];

            whiteBlocks.forEach(block => {
                const coords = getCoords(block);
                triggers.push([coords.top - header.clientHeight * 0.33, coords.bottom - header.clientHeight * 0.33]);
            })

            function check() {
                const currentY = window.pageYOffset;

                const currentYBottom = window.pageYOffset + document.documentElement.clientHeight;

                let setDark = false;

                let setSocialsDark = false;

                if (currentY > footerTrigger - 1000) {
                    socials.classList.add('fixed-socials--hidden');
                } else {
                    socials.classList.remove('fixed-socials--hidden');
                }

                if (currentY > 200) {
                    header.classList.add("header--dark");
                } else {
                    header.classList.remove("header--dark");
                }

                for (let i = 0; i < triggers.length; i++) {
                    if (currentY >= triggers[i][0] && currentY <= triggers[i][1]) {
                        setDark = true;
                        break;
                    } else {
                        setDark = false;
                    }
                }

                for (let i = 0; i < triggers.length; i++) {
                    if (currentYBottom - 100 >= triggers[i][0] && currentYBottom - 100 <= triggers[i][1]) {
                        setSocialsDark = true;
                        break;
                    } else {
                        setSocialsDark = false;
                    }
                }

                // if (setDark) {
                //     header.classList.add('header--dark');
                // } else {
                //     header.classList.remove('header--dark');
                // }

                if (socials) {
                    if (setSocialsDark) {
                        socials.classList.add('fixed-socials--dark');
                    } else {
                        socials.classList.remove('fixed-socials--dark');
                    }
                }
            }

            check();

            window.addEventListener('scroll', check, false);
        }
    }
}