export default function initZillaSelect() {
    const selects = Array.from(document.querySelectorAll('.js-zilla-select'));

    selects.forEach(select => {
        const btn = select.querySelector('.js-head');
        const icon = select.querySelector('.js-icon');
        const body = select.querySelector('.js-body');

        btn.addEventListener('click', () => {
            icon.classList.toggle('opened');
            body.classList.toggle('opened');
        })
    })
}