import { Fancybox } from "@fancyapps/ui";

export default function initFancybox() {
    const containers = Array.from(document.querySelectorAll('.js-programmatic-fancybox'));
    containers.forEach(container => {
        const galleries = Array.from(container.querySelectorAll('.js-programmatic-gallery'));
        galleries.forEach(gallery => {
            let items = [];

            const elements = Array.from(gallery.querySelectorAll('.js-programmatic-gallery-item'));
            elements.forEach((element, i) => {
                const src = element.querySelector('.js-src').src;
                const thumb = element.querySelector('.js-thumb').src;
                const caption = element.querySelector('.js-caption').textContent;

                items.push({src, thumb, caption});

                element.addEventListener('click', () => {
                    Fancybox.show(items, {
                        startIndex: i
                    });
                })
            })
        })
    })
}