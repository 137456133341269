import getCoords from "./getCoords";

export default function initFixedFilter() {
    const filter = document.querySelector('.js-init-fixed-filter');
    if (filter) {
        const btn = document.querySelector('.js-fixed-filter-open-btn');
        const closeBtn = filter.querySelector('.js-fixed-filter-close');
        const header = document.querySelector('header');

        const trigger = document.querySelector('.js-show-fixed-filter');

        const triggerY = getCoords(trigger).top;

        window.addEventListener('scroll', () => {
            if (window.scrollY > triggerY) {
                filter.classList.add('visible');
                btn.classList.add('visible');
            } else {
                filter.classList.remove('visible');
                btn.classList.remove('visible');

                filter.classList.remove('active');
                header.classList.remove('hidden');
            }
        })

        btn.addEventListener('click', () => {
            filter.classList.add('active');
            header.classList.add('hidden');
        })

        closeBtn.addEventListener('click', () => {
            filter.classList.remove('active');
            header.classList.remove('hidden');
        })

        window.addEventListener('click', e => {
            const target = e.target
            if (!target.closest('.js-init-fixed-filter') && !target.closest('.js-fixed-filter-open-btn')) {
                filter.classList.remove('active');
                header.classList.remove('hidden');
            }
        })

        document.addEventListener('keydown', (event) => {
            if (event.key === 'Escape') {
                filter.classList.remove('active');
                header.classList.remove('hidden');
            }
        })
    }
}