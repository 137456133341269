import {Swiper, EffectFade} from "swiper";
import getCoords from "./getCoords";

Swiper.use([EffectFade]);

export default function initBenefitsTabs() {
    const tabsContainers = Array.from(document.querySelectorAll('.js-init-slider-benefits-tabs'));
    tabsContainers.forEach((tabsElem) => {
        const swiperContainer = tabsElem.querySelector('.swiper');

        let swiper;

        if (document.documentElement.clientWidth > 641) {
            swiper = new Swiper(swiperContainer, {
                speed: 500,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true
                },
                allowTouchMove: false
            })
        } else {
            swiper = new Swiper(swiperContainer, {
                speed: 500,
                slidesPerView: 1,
                spaceBetween: 40
            })

            swiper.on('slideChange', (swiper) => {
                setActiveTab(swiper.activeIndex);
            })
        }


        const btns = Array.from(tabsElem.querySelectorAll('.js-nav'));

        let clicked = false;

        btns.forEach((btn, i) => {
            btn.addEventListener('click', () => {
                clicked = true;
                setActiveTab(i);
                swiper.slideTo(i);
            })
        })

        const triggerY = getCoords(tabsElem).top;
        if (document.documentElement.clientWidth > 641) {
            window.addEventListener('scroll', () => {
                const currentY = window.scrollY - 30;

                if (!clicked) {
                    if (currentY >= triggerY) {
                        setActiveTab(1);
                        swiper.slideTo(1);
                    } else {
                        setActiveTab(0);
                        swiper.slideTo(0);
                    }
                }
            }, false);
        }

        function setActiveTab(index) {
            btns.forEach((btn, k) => {
                if (k === index) {
                    btn.classList.add('active');
                } else {
                    btn.classList.remove('active');
                }
            })
        }
    })
}