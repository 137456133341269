import {Swiper} from "swiper";

export default function routesSlider() {
    const container = document.querySelector('.js-init-routes-slider');
    if (!container) return;

    // Настраиваем показ картинок по клику на аккордионы
    if (window.matchMedia("(min-width: 1023px)").matches) {

        const imagesSliderElement = container.querySelector('.js-images-slider');
        const imagesSlider = new Swiper(imagesSliderElement, {
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            },
            allowTouchMove: false,
            autoHeight: true,
            initialSlide: imagesSliderElement.dataset.initialSlide
        });

        const accordionButtons = Array.from(container.querySelectorAll('.routes__item-btn'));
        accordionButtons.forEach((button, i) => {
            button.addEventListener('click', () => {
                imagesSlider.slideTo(i);
            })
        })
    }

    const tabsContainer = container.querySelectorAll('.js-init-routes-slider');
    tabsContainer.forEach(tabsContainer => {
        const tabs = tabsContainer.querySelectorAll('.js-tab');
        const bodies = tabsContainer.querySelectorAll('.js-body');

        tabs.forEach((tab, i) => {
            tab.addEventListener('click', () => {
                bodies.forEach((body, j) => {
                    if (i == j) {
                        tabs[j].classList.add('active');
                        bodies[j].classList.add('active');
                    } else {
                        tabs[j].classList.remove('active');
                        bodies[j].classList.remove('active');
                    }
                })
            })
        })
    })
}