import '../libs/hystmodal.min.js';

export default function initModals() {
  const hystmodal = new HystModal({
      linkAttributeName: "data-hystmodal",
      beforeOpen: (modal) => {
        initMemberDetailModal(modal);
      },
      afterClose: (modal) => {
        clearVideoModal(modal);
      }
  });

  window.modalApi = hystmodal;

  initVideoModals();
}

function initVideoModals(modal) {
  const videoModal = document.querySelector('#video-modal');
  if (!videoModal) return;

  const iframe = videoModal.querySelector('iframe');

  const openBtns = Array.from(document.querySelectorAll('.js-open-video-modal'));
  openBtns.forEach(btn => {
    btn.addEventListener('click', () => {
      iframe.src = `https://www.youtube.com/embed/${btn.dataset.videoCode}?autoplay=1&rel=0`
    })
  })
}

function clearVideoModal(modal) {
  if (modal.openedWindow.id == "video-modal") {
    // Возможно, можно лучше
    modal.openedWindow.querySelector('iframe').src = "";
  }
}

function initMemberDetailModal(modal) {
  if (modal.openedWindow.id == "member-detail") {
    let realElement = modal.starter;
    if (realElement.classList.contains('js-member-plan-item')) {
      realElement = realElement.querySelector('.js-modal-item');
    }
    const modalElement = modal.openedWindow;

    // Title
    const realTitle = realElement.querySelector('.js-modal-item-name');
    const modalTitle = modalElement.querySelector('.members__modal-title');
    modalTitle.textContent = realTitle.textContent;

    // Image
    const realImage = realElement.dataset.image;
    const modalImage = modalElement.querySelector('.members__image');
    const modalImageContainer = modalImage.closest('.members__info');
    if (realImage) {
      modalImage.classList.remove('hidden');
      modalImageContainer.classList.remove('no-image');
      modalImage.src = realImage;
      modalImage.alt = realTitle.textContent;
    } else {
      modalImage.classList.add('hidden');
      modalImageContainer.classList.add('no-image');
    }

    // Phone
    const realPhones = Array.from(realElement.querySelectorAll('.js-modal-item-name-phone'));
    const modalPhonesContainer = modalElement.querySelector('.members__modal-link-list--phone');
    const modalPhoneContainer = modalPhonesContainer.closest('.members__modal-link-item');
    modalPhonesContainer.innerHTML = "";
    if (realPhones) {
      modalPhoneContainer.classList.remove('hidden');
      realPhones.forEach(phone => {
        const tel = phone.dataset.tel;

        const element = _getTemplateBySelector('#member-modal-link-item');
        const link = element.querySelector('.members__modal-link');
        link.textContent = phone.textContent;
        // link.href = `tel:${tel}`;
        modalPhonesContainer.append(link);
      })
    } else {
      modalPhoneContainer.classList.add('hidden');
    }

    // Email
    const realEmail = realElement.querySelector('.js-modal-item-name-email');
    const modalEmail = modalElement.querySelector('.members__modal-link--email');
    const modalEmailContainer = modalEmail.closest('.members__modal-link-item');
    modalEmail.textContent = "";
    // modalEmail.href = "";
    if (realEmail && realEmail.textContent) {
      modalEmailContainer.classList.remove('hidden');
      modalEmail.textContent = realEmail.textContent;
      // modalEmail.href = `mailto:${realEmail.textContent}`;
    } else {
      modalEmailContainer.classList.add('hidden');
    }

    // Site
    const realSite = realElement.querySelector('.js-modal-item-name-site');
    const modalSite = modalElement.querySelector('.members__modal-link--site');
    const modalSiteContainer = modalSite.closest('.members__modal-link-item');
    modalSite.textContent = "";
    // modalSite.href = "";
    if (realSite && realSite.textContent) {
      modalSiteContainer.classList.remove('hidden');
      modalSite.textContent = realSite.textContent;
      // modalSite.href = `http://${realSite.textContent}`;
    } else {
      modalSiteContainer.classList.add('hidden');
    }

    // Description
    const realDescription = realElement.querySelector('.js-modal-item-name-description');
    const modalDescription = modalElement.querySelector('.members__modal-description');
    if(realDescription) {
      modalDescription.textContent = realDescription.textContent;
    } else {
      modalDescription.textContent = "";
    }
  }
}

function _getTemplateBySelector(templateSelector) {
  return document
      .querySelector(templateSelector)
      .content
      .children[0]
      .cloneNode(true);
}