export default function generateLayout() {
    const containers = Array.from(document.querySelectorAll('.js-show-layout'));
    containers.forEach(container => {
        const columns = container.dataset.columns;
        const rows = container.dataset.rows;
        for(let i = 0; i < columns; i++) {
            for(let j = 0; j < rows; j++) {
                const item = document.createElement('span');
                item.textContent = `${j}:${i}`
                container.append(item);
            }
        }
    })
}