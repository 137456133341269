import getCoords from "./getCoords";

export default function initMembersNav() {
    const container = document.querySelector('.js-init-members-nav');
    const header = document.querySelector('.header');
    if (header) {
        document.documentElement.style.setProperty('--js-header-height', header.clientHeight + 'px');
    }

    if (container && header) {
        container.style.height = `${container.clientHeight}px`;
        const nav = container.querySelector('.members-nav');

        nav.style.position = "absolute";
        const navTop = getCoords(nav).top;
        let lastScrollTop = 0;
        window.addEventListener('scroll', () => {
            document.documentElement.style.setProperty('--js-header-height', header.clientHeight + 'px');

            if (window.scrollY >= navTop - header.clientHeight) {
                nav.classList.add('fixed');

                let st = window.pageYOffset || document.documentElement.scrollTop;
                if (st > lastScrollTop){
                    header.classList.add('hidden');
                    nav.classList.remove('show-header');
                } else {
                    header.classList.remove('hidden');
                    nav.classList.add('show-header');
                }
                lastScrollTop = st <= 0 ? 0 : st;
            } else {
                nav.classList.remove('show-header');
                nav.classList.remove('fixed');
            }
        },
        {
            passive: true
        });
    }
}