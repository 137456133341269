// import FormValidator from "../build-in/validation";
import Pristine from "../../../node_modules/pristinejs/dist/pristine";

export default function initValidation() {
    window.Pristine = Pristine;
    placeholders();
    fixChoices();
}

function placeholders() {
    const forms = Array.from(document.querySelectorAll('.js-init-validation'));

    forms.forEach(form => {
        const inputs = Array.from(form.querySelectorAll('.js-init-placeholder'));
        inputs.forEach(inputWrapper => {
            const input = inputWrapper.querySelector('.form__input');
            input.addEventListener('input', () => {
                if (input.value) {
                    inputWrapper.classList.add('filled')
                } else {
                    inputWrapper.classList.remove('filled')
                }
            })
            input.addEventListener('focus', () => {
                inputWrapper.classList.add('focused')
            })
            input.addEventListener('blur', () => {
                if (!input.value)
                    inputWrapper.classList.remove('focused')
            })
        })
    })
}

function fixChoices() {
    const multi = Array.from(document.querySelectorAll('.choices[data-type="select-multiple"]'));
    multi.forEach(elem => {
        const button = elem.querySelector('.choices__inner');
        button.addEventListener('click', () => {
            elem.classList.toggle('is-open');
        })
    })
}