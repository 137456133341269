export default function formTabs() {
    const containers = Array.from(document.querySelectorAll('.js-form-tabs'));
    containers.forEach(container => {
        const stepsLabel = container.querySelector('.js-tabs-steps');
        const tabs = Array.from(container.querySelectorAll('.js-form-tab'));
        const isEn = stepsLabel.dataset.lang == "en";
        const stepsLabelPart = isEn ? "Step" : "Шаг";
        stepsLabel.textContent = `${stepsLabelPart} 1/${tabs.length}`;
        const form = container.querySelector('form');

        const validator = new Pristine(form, {
            // class of the parent element where the error/success class is added
            classTo: 'js-validator-wrapper',
            errorClass: 'invalid',
            successClass: 'valid',
            // class of the parent element where error text element is appended
            errorTextParent: 'js-validator-wrapper',
            // type of element to create for the error text
            errorTextTag: 'span',
            // class of the error text element
            errorTextClass: 'form__input-error'
        }, true);

        tabs.forEach((tab, i) => {
            const prevBtn = tab.querySelector('.js-prev-tab');

            if (prevBtn) {
                prevBtn.addEventListener('click', () => {
                    setActive(tabs, i-1);
                    stepsLabel.textContent = `${stepsLabelPart} ${i}/${tabs.length}`;
                })
            }
        })
    })

    function setActive(elements, index) {
        elements.forEach((elem, i) => {
            if(i === index) {
                elem.classList.add('active');
            } else {
                elem.classList.remove('active');
            }
        })
    }
}