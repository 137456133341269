import 'parsleyjs';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend(customParseFormat);

window.Parsley.addValidator('requiredIfChecked', {
    requirementType: 'string',
    validateString: function (value, requirement) {
        console.log('Validating', value);

        const checkbox = document.querySelector(requirement);

        if (!checkbox) {
            return false;
        }

        if (checkbox.checked && !value.trim()) {
            return false;
        }

        return true;
    },
    messages: {
        en: 'Required field',
        ru: 'Обязательное поле',
    },
    priority: 33,
});

window.Parsley.addValidator('phone', {
    requirementType: 'string',
    validateString: function (value) {
        if (value.trim() === '') return true;
        return /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/.test(value);
    },
    messages: {
        en: 'This value should be a mobile number',
        ru: 'Введите правильный номер мобильного телефона',
    },
});

window.Parsley.addValidator('date', {
    requirementType: 'string',
    validateString: function (value) {
        if (value.trim() === '') return true;
        return dayjs(value, 'DD.MM.YYYY', true).isValid();
    },
    messages: {
        en: 'Enter correct date',
        ru: 'Введите правильно дату',
    },
});

Parsley.addMessages('ru', {
    defaultMessage: 'Некорректное значение.',
    type: {
        email: 'В данном поле может быть только E-mail',
        url: 'Адрес сайта введен неверно.',
        number: 'Введите число.',
        integer: 'Введите целое число.',
        digits: 'Введите только цифры.',
        alphanum: 'Введите буквенно-цифровое значение.',
    },
    notblank: 'Это поле должно быть заполнено.',
    required: 'Обязательное поле',
    pattern: 'Это значение некорректно.',
    min: 'Это значение должно быть не менее чем %s.',
    max: 'Это значение должно быть не более чем %s.',
    range: 'Это значение должно быть от %s до %s.',
    minlength: 'Это значение должно содержать не менее %s символов.',
    maxlength: 'Это значение должно содержать не более %s символов.',
    length: 'Это значение должно содержать от %s до %s символов.',
    mincheck: 'Выберите не менее %s значений.',
    maxcheck: 'Выберите не более %s значений.',
    check: 'Выберите от %s до %s значений.',
    equalto: 'Это значение должно совпадать.',
});

Parsley.setLocale('ru');

export default function validation() {
    const formsToValidate = Array.from(document.querySelectorAll('form[data-need-validation]'));

    formsToValidate.forEach((form) => {
        const instance = $(form).parsley();

        const tabs = Array.from(form.querySelectorAll('.js-form-tab'));
        const stepsLabel = document.querySelector('.js-tabs-steps');
        const isEn = stepsLabel.dataset.lang == "en";
        const stepsLabelPart = isEn ? "Step" : "Шаг";

        const nextBtn = form.querySelector('.js-next-tab');
        if (nextBtn) {
            nextBtn.addEventListener('click', () => {
                instance.validate({
                    group: "first-tab",
                    force: false
                });

                const isValid = instance.isValid({
                    group: "first-tab",
                    force: false
                })

                if (isValid && !(window.countrySelect.passedElement.element.classList.contains('parsley-error') || window.regionSelect.passedElement.element.classList.contains('parsley-error') || window.citySelect.passedElement.element.classList.contains('parsley-error'))) {
                    // Yandex Metrika
                    const formGoal = nextBtn.dataset.goal;
                    if (formGoal && window.ym) {
                        ym(88241357, 'reachGoal', formGoal);
                    }

                    setActive(tabs, 1);
                    stepsLabel.textContent = `${stepsLabelPart} 2/2`;
                }
            });
        }

        window.Parsley.on('form:validated', (f) => {
            const errorSelects = Array.from(f.element.querySelectorAll('select.parsley-error'));
            const successSelects = Array.from(f.element.querySelectorAll('select.parsley-success'));

            [...errorSelects, ...successSelects].forEach(select => {
                select.addEventListener('change', () => {
                    instance.validate({
                        group: `select-${select.dataset.group}`,
                        force: false
                    });
                })
            })

            const errors = Array.from(f.element.querySelectorAll('.parsley-error'));

            errors.forEach(error => {
                const parent = error.closest('.js-validator-wrapper');
                if (parent) {
                    parent.classList.add('invalid');
                    parent.classList.remove('valid');
                }
            })

            const success = Array.from(f.element.querySelectorAll('.parsley-success'));

            success.forEach(s => {
                const parent = s.closest('.js-validator-wrapper');
                if (parent) {
                    parent.classList.remove('invalid');
                    parent.classList.add('valid');
                }
            })
        })
    });
}

function setActive(elements, index) {
    elements.forEach((elem, i) => {
        if(i === index) {
            elem.classList.add('active');
        } else {
            elem.classList.remove('active');
        }
    })
}
