import gsap from 'gsap';
import {Swiper, Navigation} from "swiper";

Swiper.use([Navigation]);

gsap.config({
    nullTargetWarn: false
});

export default function tabs() {
    const container = document.querySelector('.js-init-tabs');
    if (container) {
        const tabsContainer = container.querySelector('.js-tabs-container');
        const tabs = Array.from(tabsContainer.querySelectorAll('.js-tab'));
        const contents = Array.from(container.querySelectorAll('.js-body'));

        const hash = document.location.hash;

        const schemeSlider = container.querySelector('.js-init-scheme-slider');

        let swiper = null;

        if (schemeSlider) {
            const plan3d = container.querySelector('.js-init-3d-plan');
            const plan3dElements = plan3d.querySelectorAll('.js-3d-plan-element');

            plan3dElements.forEach((element, k) => {
                element.addEventListener('click', () => {
                    swiper.slideToLoop(k);
                    tabActivate(element.dataset.name);
                })
            })

            const swiperContainer = schemeSlider.querySelector('.js-main-slider');

            swiper = new Swiper(swiperContainer, {
                slidesPerView: 3,
                spaceBetween: 20,
                loop: true,
                centeredSlides: true,
                watchSlidesProgress: true,
                breakpoints: {
                    320: {
                        spaceBetween: 20,
                    },
                    641: {
                        spaceBetween: 20,
                    }
                },
                navigation: {
                    nextEl: schemeSlider.querySelector('.js-init-slider-btn-next'),
                    prevEl: schemeSlider.querySelector('.js-init-slider-btn-prev')
                },
                watchOverflow: true,
            });

            swiper.on('slideChange', () => {
                if (swiper.slides[swiper.activeIndex]) {
                    tabActivate(swiper.slides[swiper.activeIndex].dataset.name);
                }
            })
        }

        tabs.forEach((tab, k) => {
            tab.addEventListener('click', (e) => {
                if(swiper) {
                    swiper.slideToLoop(k);
                    if (window.matchMedia("(max-width: 1024px)").matches) {
                        tabActivate(tab.dataset.name);
                    }
                } else {
                    tabActivate(tab.dataset.name);
                }
            })
        })

        if (hash) {
            const name = hash.slice(1);
            tabActivate(name);

            if(swiper) {
                let j = 0;
                tabs.some((tab, i) => {
                    if (tab.dataset.name == name) {
                        j = i;
                        return true;
                    }
                });
                swiper.slideToLoop(j);
            }
        }

        function tabActivate(dataName) {
            const content = contents.find(content => content.dataset.name == dataName);

            if (content) {
                tabs.forEach((tab) => {
                    tab.classList.remove('active');
                    if (tab.dataset.name === dataName) {
                        tab.classList.add('active');
                    }
                });

                contents.forEach(tabContent => {
                    tabContent.classList.remove('active')
                    if (tabContent === content) {
                        tabContent.classList.add('active')
                    }
                })

                document.location.hash = '#' + dataName;
            }
        }
    }
}

