import Choices from 'choices.js';

export default function initSelects() {
    const selects = Array.from(document.querySelectorAll('.js-select'));
    window.choicesItems = [];

    selects.forEach(select => {
        const input = select.closest('.js-validate-multiple') ? select.closest('.js-validate-multiple').querySelector('input') : null;

        const choices = new Choices(select, {
            searchEnabled: false,
            itemSelectText: '',
            shouldSort: false,
            renderSelectedChoices: 'always',
            resetScrollPosition: false,
        });

        const countrySelectWrapper = select.closest('.js-country-select');
        if (countrySelectWrapper) {
            window.countrySelect = choices;
        }

        const regionSelectWrapper = select.closest('.js-region-select');
        if (regionSelectWrapper) {
            window.regionSelect = choices;
        }

        const citySelectWrapper = select.closest('.js-city-select');
        if (citySelectWrapper) {
            window.citySelect = choices;
        }

        if (select.hasAttribute('multiple')) {
            choices.passedElement.element.addEventListener('choice', function (event) {
                choices.getValue(true).forEach(item => {
                    if (item !== event.detail.choice.value) return;
                    setTimeout(() => {
                        choices.removeActiveItemsByValue(event.detail.choice.value);
                        setValue(choices, input);
                    }, 100)
                });
            });

            choices.passedElement.element.addEventListener('change', () => {
                setValue(choices, input);
            })

            setValue(choices, input);

            const multipleLabel = select.dataset.name || "";
            select.parentElement.textContent = multipleLabel;
        }

        function setValue(choices, input) {
            if (input) {
                input.value = choices.getValue(true).length ? JSON.stringify(choices.getValue(true)) : "";
                const evt = document.createEvent('HTMLEvents');
                evt.initEvent('input', true, true);
                input.dispatchEvent(evt);
            }
        }

        window.choicesItems.push(choices);
    })

    if (window.citySelect && window.regionSelect && window.countrySelect) {
        window.citySelect.disable();
        window.regionSelect.disable();

        window.countrySelect.passedElement.element.addEventListener('choice', (e) => {
            window.citySelect.disable();
            window.regionSelect.disable();
            fetch(`/local/action/getRegion.php?country=${e.detail.choice.value}&lang=${window.countrySelect.passedElement.element.dataset.lang}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Ответ сети был не ok.");
                    }
                    return response.json();
                })
                .then(data => {
                    if (data && data.length > 1) {
                        window.regionSelect.enable();
                    } else if (data) {
                        // Иначе, если регионы пустые, показываем сразу города в стране
                        fetch(`/local/action/getCity.php?country=${e.detail.choice.value}&lang=${window.countrySelect.passedElement.element.dataset.lang}`)
                            .then(response => {
                                if (!response.ok) {
                                    throw new Error("Ответ сети был не ok.");
                                }
                                return response.json();
                            })
                            .then(data => {
                                if (data && data.length > 1) {
                                    window.citySelect.enable();
                                }
                                window.citySelect.clearChoices();
                                window.citySelect.setValue(data);
                                if (data.length > 1) {
                                    window.citySelect.passedElement.element.value = "";
                                    window.citySelect.passedElement.element.required = true;
                                } else {
                                    clearChoicesStyles(window.citySelect)
                                }
                            })
                            .catch((err) => {
                                console.error(err);
                            })
                    }

                    window.regionSelect.clearChoices();
                    window.regionSelect.setValue(data);

                    if (data.length > 1) {
                        window.regionSelect.passedElement.element.value = "";
                        window.regionSelect.passedElement.element.required = true;
                    } else {
                        clearChoicesStyles(window.regionSelect)
                    }

                })
                .catch((err) => {
                    console.error(err);
                })
        })

        window.regionSelect.passedElement.element.addEventListener('choice', (e) => {
            window.citySelect.disable();
            fetch(`/local/action/getCity.php?region=${e.detail.choice.value}&lang=${window.regionSelect.passedElement.element.dataset.lang}`)
                .then(response => {
                    if (!response.ok) {
                        throw new Error("Ответ сети был не ok.");
                    }
                    return response.json();
                })
                .then(data => {
                    if (data && data.length > 1) {
                        window.citySelect.enable();
                    }
                    window.citySelect.clearChoices();
                    window.citySelect.setValue(data);
                    if (data.length > 1) {
                        window.citySelect.passedElement.element.value = "";
                        window.citySelect.passedElement.element.required = true;
                    } else {
                        clearChoicesStyles(window.citySelect)
                    }
                })
                .catch((err) => {
                    console.error(err);
                })
        })

        window.countrySelect.passedElement.element.addEventListener('change', (e) => {
            clearChoicesStyles(window.regionSelect)
            clearChoicesStyles(window.citySelect)
        })

        window.regionSelect.passedElement.element.addEventListener('change', (e) => {
            if (window.regionSelect.passedElement.element.value === "N") {
                window.regionSelect.passedElement.element.value = "";
            }
        })

        window.citySelect.passedElement.element.addEventListener('change', (e) => {
            if (window.citySelect.passedElement.element.value === "N") {
                window.citySelect.passedElement.element.value = "";
            }
        })
    }
}

function clearChoicesStyles(select) {
    select.passedElement.element.removeAttribute('required');
    select.passedElement.element.classList.remove('parsley-success');
    select.passedElement.element.classList.remove('parsley-error');
    const parent =  select.passedElement.element.closest('.js-validator-wrapper');
    if (parent) {
        parent.classList.remove('valid');
        parent.classList.remove('invalid');
    }
}