import alignHeights from "./alignHeights";

export default function fix() {
  fixFlexPaddingBottom();
  fixFormatsCardsImageHeight('.formats__slide--view-2', '--card-view-2-title-height');
  fixFormatsCardsImageHeight('.formats__slide--view-3', '--card-view-3-title-height');
  fixSectionsBgImageOnMobile();
  fixTabletMapMargin();

  alignHeights('.partners-slider__slider-wrapper', '.partners-slider__slide-label');
  alignHeights('.partners__grid-list', '.partners__grid-list-item-label');
  alignHeights('.partners-list__grid', '.partners-list__label');
  tourShowMoreToggleText();
  placePhotoSliderFix();
}

function fixFlexPaddingBottom() {
  document.documentElement.style.setProperty('--flex-padding-bottom', '56.25%');
}

function fixFormatsCardsImageHeight(selector, cssVarName) {
  const cards = Array.from(document.querySelectorAll(selector));

  let maxHeight = 0;

  cards.forEach(card => {
    const title = card.querySelector('.formats__slide-title');
    if (title && title.clientHeight > maxHeight) {
      maxHeight = title.clientHeight;
    }
  })

  document.documentElement.style.setProperty(cssVarName, maxHeight / 10 + 'rem');
}

function fixSectionsBgImageOnMobile() {
  if (document.documentElement.clientWidth <= 640) {
    const image = document.querySelector('.sections__bg');
    const sectionsContainer = document.querySelector('.sections__inner');

    if (image && sectionsContainer) {
      image.style.height = `${sectionsContainer.clientHeight + 200}px`;
    }
  }
}

function fixTabletMapMargin() {
  if (document.documentElement.clientWidth <= 1024) {
    const mapHeader = document.querySelector('.map__header');
    const mapInfoBlock = document.querySelector('.map__place-info');

    if (mapHeader && mapInfoBlock) {
      mapHeader.style.marginBottom = mapInfoBlock.clientHeight + 'px';
    }
  }
}

function tourShowMoreToggleText() {
  const containers = Array.from(document.querySelectorAll('.js-tours-show-more-container'));
  containers.forEach((container, i) => {
    const accordionContainers = Array.from(container.querySelectorAll('.js-tour-accordion'));
    const btns = Array.from(container.querySelectorAll('.tour__show-more'));

    btns.forEach(btn => {
      btn.addEventListener('click', () => {
        accordionContainers.forEach(ac => {
          const b = ac.querySelector('.tour__show-more');
          const label = b.querySelector('span');
          setTimeout(() => {
            if (ac.classList.contains('active')) {
              label.textContent = "Скрыть";
            } else {
              label.textContent = "Показать";
            }
          }, 400)
        })
      })
    })
  })
}

function placePhotoSliderFix() {
  const sliders = Array.from(document.querySelectorAll('.place-photos__slider-container'));
  sliders.forEach(slider => {
    const lockedBtn = slider.querySelector('.swiper-button-lock');
    if (lockedBtn) {
      const sliderWrapper = slider.querySelector('.place-photos__slider');
      sliderWrapper.classList.add('no-mask');
    }
  })
}