import loadApi from "./loadApi";

export default function initYouTubePlayers() {
    if (document.documentElement.clientWidth >= 1024) {
        loadApi('youtube', 'https://www.youtube.com/iframe_api');
    }

    window.onYouTubeIframeAPIReady = () => {
        const players = Array.from(document.querySelectorAll('.js-youtube-player'));
        players.forEach(playerElement => {
            const player = new YT.Player(playerElement, {
                videoId: playerElement.dataset.videoCode,
                playerVars: {
                    'modestbranding': 1,
                    'autoplay': 0,
                    'controls': 0,
                    'rel': 0,
                    'showinfo': 0,
                    'disablekb': 0,
                    'mute': 1
                },
                events: {
                    'onReady': onPlayerReady,
                    'onStateChange': onStateChange
                },
                setPlaybackQuality: "small"
            });
        });

        function onStateChange(event) {
            if (event.data === YT.PlayerState.ENDED) {
                event.target.playVideo();
            }
        }

        function onPlayerReady(event) {
            event.target.playVideo();
            event.target.mute();
            event.target.setVolume(0);
        }
    }
}