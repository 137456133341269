import getCoords from "./getCoords";


export default function formatsMobileOnScroll() {
    if (document.documentElement.clientWidth <= 640) {
        const container = document.querySelector('.js-scroll-formats-container');
        if (container) {
            const items = Array.from(container.querySelectorAll('.js-scroll-formats-item'));

            let triggers = [];

            items.forEach((item, i) => {
                triggers.push([getCoords(item).top - item.clientHeight / 4, getCoords(item).bottom + item.clientHeight / 4]);
            })

            function check() {
                const currentYTrigger = window.pageYOffset + document.documentElement.clientHeight / 2;

                items.forEach((item, i) => {
                    let setActive = false;

                    if (currentYTrigger >= triggers[i][0] && currentYTrigger <= triggers[i][1]) {
                        setActive = true;
                    } else {
                        setActive = false;
                    }

                    if (setActive) {
                        item.classList.add('active');
                    } else {
                        item.classList.remove('active');
                    }
                })
            }
            check();

            window.addEventListener('scroll', check, false);
        }
    }
}