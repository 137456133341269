import gsap from 'gsap';
import {Swiper, Navigation, Pagination, Grid, Thumbs} from "swiper";

Swiper.use([Navigation, Pagination, Grid, Thumbs]);

gsap.config({
  nullTargetWarn: false
});

export default function initSliders() {
  const sliders = Array.from(document.querySelectorAll('.js-init-slider'));
  sliders.forEach(slider => {
    const swiperContainer = slider.querySelector('.js-main-slider');

    if (swiperContainer.dataset.disable) {
      slider.classList.add('disabled-slider');
      return;
    }

    let settings = {};
    let delay = null;

    if (swiperContainer.dataset.settings) {
      settings = eval(swiperContainer.dataset.settings);
    }
    if (slider.dataset.delay) {
      delay = Number(slider.dataset.delay);
    }
    if (document.documentElement.clientWidth <= 640 && settings.disableOnMobile) {
      return;
    }

    if (settings) {
      let navigation = {};
      let nextBtn;
      let prevBtn;
      if (!settings.navigation) {
        nextBtn = slider.querySelector('.js-init-slider-btn-next');
        prevBtn = slider.querySelector('.js-init-slider-btn-prev');

        if (nextBtn && prevBtn) {
          navigation =  {
            nextEl: nextBtn,
            prevEl: prevBtn
          }
        }
      } else {
        navigation = settings.navigation;

        nextBtn = navigation.nextEl;
        prevBtn = navigation.prevEl;
      }

      let pagination = {};

      const paginationContainer = slider.querySelector('.js-init-slider-pagination');
      if (paginationContainer) {
        pagination = {
          el: paginationContainer,
          clickable: true
        }
      }

      let thumbsSlider = {};
      if (settings.hasThumbs) {
        const thumbsSliderElement = slider.querySelector('.js-init-thumbs');
        if (document.documentElement.clientWidth > 640) {
          thumbsSlider = new Swiper(thumbsSliderElement, {
            spaceBetween: 20,
            slidesPerView: 7,
            freeMode: true,
            watchSlidesProgress: true,
            breakpoints: {
              320: {
                spaceBetween: 10,
                slidesPerView: 3,
              },
              769: {
                spaceBetween: 14,
                slidesPerView: 5,
              },
              1025: {
                spaceBetween: 20,
                slidesPerView: 7,
              }
            }
          });

          settings.thumbs = {swiper: thumbsSlider}
        } else {
          thumbsSliderElement.remove();
        }

      }

      const swiper = new Swiper(swiperContainer, {
        ...settings,
        navigation,
        pagination,
        watchOverflow: true,
      });

      // Управление автоплеем
      // Управление анимацией кнопки "Врепед"
      if(delay && nextBtn) {
        const btnAnimatedLine = nextBtn.querySelector('.slider-next-line');

        const animation = gsap.timeline();

        // kostil
        let isSlidesPerViewBug = false;
        let lastSlideIndex = -1;

        function initLineAnimation() {
          animation.clear();
          animation.eventCallback('onComplete', null);
          animation.fromTo(btnAnimatedLine, {
              strokeDashoffset: 128.74,
            }, {
              ease: "none",
              strokeDashoffset: 0,
              duration: delay/1000,
            }
          );

          animation.eventCallback('onComplete', () => {
            if(swiper.isEnd) {
              swiper.slideTo(0);
            }
            else {
              swiper.slideNext();

              if (swiperContainer.classList.contains('formats__slider')) {
                if (swiper.activeIndex === lastSlideIndex) {
                  isSlidesPerViewBug = true;
                  initLineAnimation();
                }
                lastSlideIndex = swiper.activeIndex;
              }
            }
          });
        }

        swiper.on('slideChange', () => {
          initLineAnimation();
        })

        initLineAnimation();
      } else if(nextBtn) {
        // Если автоплей отключен, то делаем кнопку "Следующий слайд" с прозрачностью 1
        nextBtn.querySelector('.slider-next-circle').style.opacity = "1";
      }

    } else {
      console.warn(`Для слайдера [${slider.classList}] добавьте обязательные атрибуты: data-settings (Имя переменной с объектом настроек) и data-delay (задержка автовоспроизведения в миллисекундах).`)
    }
  })
}