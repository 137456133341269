import {Swiper, EffectFade} from "swiper";
import stickybits from "stickybits";
import getCoords from "./getCoords";

Swiper.use([EffectFade]);

export default function initBackgroundImages() {
    const container = document.querySelector('.js-init-background-images');

    if (container) {
        const sliderContainer = container.querySelector('.js-bg-images-slider');

        // Дока: https://github.com/yowainwright/stickybits
        const stickyAnimation = stickybits(sliderContainer, {
            useFixed: true
        });

        const swiper = new Swiper(sliderContainer, {
            speed: 1000,
            effect: 'fade',
            fadeEffect: {
                crossFade: true
            }
        });

        // Теперь при скролле нужно находить текущую активную секцию
        // и включать на фоне соответствующий слайд.
        // Связь между слайдом и секцией определяется по индексу
        const sections = Array.from(container.querySelectorAll('section'));

        let yTriggers = [0];
        // Для всех, кроме последней
        for(let i = 0; i < sections.length - 1; i++) {
            const sectionCoords = getCoords(sections[i]);
            const sectionYCenter = sectionCoords.bottom;

            yTriggers.push(sectionYCenter);
        }

        let currentSectionId = 0;
        let currentDigitId = 0;

        // Определяем текущий слайд


        window.addEventListener('scroll', (e) => {
            toggleSlider();
        });

        function toggleSlider() {
            const windowCenterYCoord = window.pageYOffset + (document.documentElement.clientHeight / 2);

            if (windowCenterYCoord <= yTriggers[yTriggers.length - 1]) {
                for (let i = yTriggers.length - 1; i >= 0; i--) {
                    if (windowCenterYCoord >= yTriggers[i]) {
                        currentSectionId = i;
                        break;
                    }
                }
                swiper.slideTo(currentSectionId);
            } else {
                swiper.slideTo(currentDigitId + sections.length - 1);
            }
        }

        // Если страница загрузилась ниже, чем блок изображениями, то фиксим баг
        if (window.pageYOffset + document.documentElement.clientHeight > getCoords(sections[sections.length - 1]).bottom) {
            sliderContainer.style.position = "absolute";
            sliderContainer.style.top = "unset";
            sliderContainer.style.bottom = "0";
        }

        toggleSlider();

        // Для блока "Цифры"
        const digitsContainer = document.querySelector('.js-init-digits-list');
        if (digitsContainer) {
            const buttons = Array.from(digitsContainer.querySelectorAll('.digits__btn'));
            buttons[currentDigitId].classList.add('active');

            buttons.forEach((btn, i) => {
                let timer;
                btn.addEventListener('mouseover', () => {
                    timer = setTimeout(() => {
                        currentDigitId = i;
                        swiper.slideTo(currentDigitId + sections.length - 1);
                        buttons.forEach((btn, j) => {
                            if (currentDigitId == j) {
                                btn.classList.add('active');
                            } else {
                                btn.classList.remove('active');
                            }
                        })
                    }, 200);
                })

                btn.addEventListener('mouseout', () => {
                    clearInterval(timer)
                });
            })
        }
    }
}