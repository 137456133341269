export default function initAutoSubmitForm() {
    const containers = Array.from(document.querySelectorAll('.js-auto-submit-form-container'));
    containers.forEach((container, i) => {
        const form = container.querySelector('.js-auto-submit-form');
        const showMoreBtn = container.querySelector('.js-ajax-show-more-btn');
        const elementsContainer = container.querySelector('.js-ajax-elements-container');

        const selects = Array.from(form.querySelectorAll('select'));
        selects.forEach(select => {
            select.addEventListener("change", (e) => {
                if (showMoreBtn) {
                    // Блокируем кнопку
                    showMoreBtn.setAttribute('disabled', 'true');
                    showMoreBtn.classList.add('disabled');
                }

                // Добавляем класс контейнеру во время загрузки данных
                elementsContainer.classList.add('loading');

                const formData = new FormData(form);
                const data = [...formData.entries()];
                const query = data
                    .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
                    .join('&');
                const url = `?${query}`;

                fetch(url, {
                    method: 'GET',
                    mode: 'no-cors',
                    headers: {
                        'Content-Type': 'text/html'
                    }
                })
                    .then(res => res.text())
                    .then(data => {
                        const parser = new DOMParser();
                        const page = parser.parseFromString(data, "text/html");

                        // Ищем все ajax контейнеры,
                        // из них понадобится только один с текущим индексом i
                        const newAjaxContainers = page.querySelectorAll('.js-ajax-show-more-container');

                        // Находим элементы, вставляем в текущую разметку
                        const newElementsContainer = newAjaxContainers[i].querySelector('.js-ajax-elements-container');
                        const newElements = newElementsContainer.querySelectorAll('.js-ajax-element');

                        elementsContainer.innerHTML = "";
                        newElements.forEach(element => {
                            elementsContainer.append(element);
                        });

                        if (showMoreBtn) {
                            // Находим новую кнопку "Показать ещё", обновляем ее href
                            // Либо удаляем
                            const newShowMoreBtn = newAjaxContainers[i].querySelector('.js-ajax-show-more-btn');

                            if (newShowMoreBtn) {
                                showMoreBtn.href = newShowMoreBtn.href;
                                showMoreBtn.classList.remove('hidden');
                            } else {
                                showMoreBtn.classList.add('hidden');
                            }
                        }
                    })
                    .catch(function (err) {
                        console.warn('Ошибка при загрузке новых карточек.', err);
                    })
                    .finally(() => {
                        if (showMoreBtn) {
                            // Разблокируем кнопку
                            showMoreBtn.removeAttribute("disabled");
                            showMoreBtn.classList.remove('disabled');
                        }

                        // Удаляем класс для загрузки
                        elementsContainer.classList.remove('loading');
                    })
            });
        })
    })
}
