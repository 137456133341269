import showMore from "./showMore";

export default function initProgramAutoSubmitForm() {
    const containers = Array.from(document.querySelectorAll('.js-auto-submit-program-form-container'));
    containers.forEach((container, i) => {
        const form = container.querySelector('.js-auto-submit-form');
        const tabs = Array.from(container.querySelectorAll('.js-ajax-program-tab'));

        const selects = Array.from(form.querySelectorAll('select'));
        selects.forEach(select => {
            select.addEventListener("change", (e) => {
                const formData = new FormData(form);
                const data = [...formData.entries()];
                const query = data
                    .map(x => `${encodeURIComponent(x[0])}=${encodeURIComponent(x[1])}`)
                    .join('&');
                const url = `?${query}`;

                fetch(url, {
                    method: 'GET',
                    mode: 'no-cors',
                    headers: {
                        'Content-Type': 'text/html'
                    }
                })
                    .then(res => res.text())
                    .then(data => {
                        const parser = new DOMParser();
                        const page = parser.parseFromString(data, "text/html");

                        // Ищем все ajax контейнеры,
                        // из них понадобится только один с текущим индексом i
                        const newAjaxContainers = page.querySelectorAll('.js-auto-submit-program-form-container');

                        // Находим элементы, вставляем в текущую разметку
                        const newTabs = Array.from(newAjaxContainers[i].querySelectorAll('.js-ajax-program-tab'));

                        tabs.forEach((tab, index) => {
                            tabs[index].innerHTML = newTabs[index].innerHTML;
                        });

                        showMore();

                    })
                    .catch(function (err) {
                        console.warn('Ошибка при загрузке новых карточек.', err);
                    })
                    .finally(() => {
                    })
            });
        })
    })
}
